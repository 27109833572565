import React from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"
import ArticleGrid from "../components/ArticleGrid"
import Article from "../components/Article"
import SeriesGrid from "../components/SeriesGrid"

const CenteredH3 = styled.h3`
  margin-bottom: 0;
  margin-top: ${rhythm(2 / 4)};
`
const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { edges } = data.allMarkdownRemark

  const recent = _.take(edges, 4)

  const posts = _.shuffle(_.takeRight(edges, edges.length - 4))

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />

      <CenteredH3>Recent Stories</CenteredH3>
      <ArticleGrid>
        {recent.map(({ node }) => (
          <Article key={node.fields.slug} node={node} random={false} />
        ))}
      </ArticleGrid>
      <CenteredH3>Featured Series</CenteredH3>
      <SeriesGrid>
        <article>
          <header>
            <h4>
              <Link style={{ boxShadow: `none` }} to="series/society">
                The Society
              </Link>
            </h4>
          </header>
          <section>
            <p>
              More of a universe than a series, the Society is a semi-secret
              group of people practicing 24/7 bdsm, and living along side
              "normal" people.
            </p>
            <p>
              These are all stories which expand on the society, from short
              images to longer series.
            </p>
          </section>
          <Link to="series/society">Read More...</Link>
        </article>
        <article>
          <header>
            <h4>
              <Link style={{ boxShadow: `none` }} to="series/cuckolder">
                Cuckolder
              </Link>
            </h4>
          </header>
          <section>
            <p>
              Brian realized early on that there was a single true thing:
              Everyone cheats. He decided that no one would ever cheat on him,
              so he refuses to be in a relationship with anyone. In fact, he
              only has sex with people who are in them. He cuckolds men and
              women, and dominates them to his will.
            </p>
            <p>
              This is a set of stories, including an origin series about Brian,
              his adventures, and how he became the way he is.
            </p>
          </section>
          <Link to="series/cuckolder">Read More...</Link>
        </article>
        <article>
          <header>
            <h4>
              <Link style={{ boxShadow: `none` }} to="series/kadja">
                Kadja
              </Link>
            </h4>
          </header>
          <section>
            <p>
              This series/univese needs a better name, but is based on the
              character Kadja who seeks out a dragon to help unlock her magical
              powers.
            </p>
          </section>
          <Link to="series/kadja">Read More...</Link>
        </article>
      </SeriesGrid>
      <CenteredH3>More Stories</CenteredH3>
      <ArticleGrid>
        {posts.map(({ node }) => (
          <Article key={node.fields.slug} node={node} random />
        ))}
      </ArticleGrid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
          }
        }
      }
    }
  }
`
export default BlogIndex
